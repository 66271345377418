import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useGetIBPortfolio } from 'src/container/ib-portfolio-get-filter';
import { useGroupsFindAllKlass } from 'src/generated/endpoint';
import { meState } from 'src/store';
import { Input } from '../common/Input';
import SelectBar from '../common/SelectBar';
import { Typography } from '../common/Typography';
import PortfolioCard from './cas/PortfolioCard';

interface FilterOption {
  grade: number;
  klass: number;
}

export default function TeacherIBPortfolioList() {
  const me = useRecoilValue(meState);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption>({
    grade: 0,
    klass: 0,
  });
  const [selectedStudentName, setSelectedStudentName] = useState('');
  const [searchStudentName, setSearchStudentName] = useState('');
  const [counts, setCounts] = useState<Record<string, number>>({});
  const { data: klassGroups } = useGroupsFindAllKlass();
  const { data, getIBPortfolio, isLoading } = useGetIBPortfolio();

  const handleOptionChange = (optionType: 'grade' | 'klass' | 'student', value: number) => {
    let updatedOptions = { ...selectedOptions };

    if (optionType === 'grade') {
      updatedOptions = {
        grade: value,
        klass: 0,
      };
    } else {
      updatedOptions = {
        ...updatedOptions,
        [optionType]: value,
      };
    }

    setSelectedOptions(updatedOptions);
  };

  const grades = [
    { id: 0, value: 0, text: '학년 전체' },
    { id: 1, value: 2, text: '2학년' },
    { id: 2, value: 3, text: '3학년' },
  ];

  const klasses = _(klassGroups)
    .filter((group) => group.grade === selectedOptions.grade)
    .map('klass')
    .uniq()
    .map((klass, index) => ({ id: index + 1, value: klass, text: `${klass}반` }))
    .concat([{ id: 0, value: 0, text: '반 전체' }])
    .orderBy('value')
    .value();

  const handleSearch = () => {
    if (!searchStudentName) {
      alert('텍스트 내용을 입력해주세요.');
    } else {
      setSelectedStudentName(searchStudentName);
    }
  };

  useEffect(() => {
    getIBPortfolio({
      grade: selectedOptions.grade === 0 ? undefined : selectedOptions.grade,
      klass: selectedOptions.klass === 0 ? undefined : selectedOptions.klass,
    });
  }, [selectedOptions]);

  if (me == null) {
    return <div>접속 정보를 불러올 수 없습니다.</div>;
  }
  return (
    <main className="w-full">
      <header className="flex flex-row items-center gap-3 py-5">
        <div className="flex w-full justify-between">
          <div className="flex flex-row items-center gap-3">
            <div className="flex flex-row items-center gap-2">
              <SelectBar
                options={grades}
                value={selectedOptions.grade}
                onChange={(value: number) => handleOptionChange('grade', value)}
                placeholder="학년 선택"
                size={40}
                containerWidth="w-30"
                dropdownWidth="w-40"
                priorityFontClass="text-primary-gray-900"
              />
              <SelectBar
                disabled={!selectedOptions.grade}
                options={klasses}
                value={selectedOptions.klass}
                onChange={(value: number) => handleOptionChange('klass', value)}
                placeholder="반 선택"
                size={40}
                containerWidth="w-30"
                dropdownWidth="w-40"
                className={clsx({ 'cursor-not-allowed': !selectedOptions.grade })}
                priorityFontClass={clsx({ 'text-primary-gray-900': selectedOptions.grade })}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <Input.Basic
              size={40}
              placeholder="이름 검색"
              type="text"
              value={searchStudentName}
              className="w-[160px]"
              isSearch
              onSearch={handleSearch}
              onChange={(e) => setSearchStudentName(e.target.value)}
            />
          </div>
        </div>
      </header>
      <section className="flex flex-col">
        {selectedStudentName && (
          <div className="flex items-center gap-4 py-4">
            <hr className="flex-1" />
            <Typography variant="body3">
              {selectedStudentName} <span className="text-primary-gray-700">검색결과</span>{' '}
              <span className="text-primary-orange-800">
                {counts['COMPLETE'] +
                  counts['IN_PROGRESS,REJECT_COMPLETE,WAIT_COMPLETE'] +
                  counts['WAIT_MENTOR,WAITING_FOR_NEXT_PROPOSAL'] +
                  counts['WAIT_PLAN_APPROVE,REJECT_PLAN,REJECT_MENTOR']}
              </span>
            </Typography>
            <hr className="flex-1" />
          </div>
        )}
        <div className="grid grid-cols-4 gap-4">
          {data?.items.map((project) => {
            return <PortfolioCard data={project} key={project.id} />;
          })}
        </div>
      </section>
    </main>
  );
}
