import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RadioV2 } from 'src/components/common/RadioV2';
import { Typography } from 'src/components/common/Typography';
import { useIBProposalStatus, useIBProposalSubmissionStatus } from 'src/container/ib-overview';
import { IBGetSubmissionStatusCountParams, IBGetSubmissionStatusStatus } from 'src/generated/model';
import ActivityPlanOverviewPanel from './ActivityPlanOverviewPanel';

export default function ActivityPlanView({ grade, klass }: IBGetSubmissionStatusCountParams) {
  const { push } = useHistory();

  const [status, setStatus] = useState<IBGetSubmissionStatusStatus>(
    () => (sessionStorage.getItem('PROJECT_PROPOSAL_STATUS') as IBGetSubmissionStatusStatus) || 'NOT_SUBMITTED',
  );

  const { data } = useIBProposalStatus({
    grade: grade === 0 ? undefined : grade,
    klass: klass === 0 ? undefined : klass,
  });

  const { students = [] } = useIBProposalSubmissionStatus({
    grade: grade === 0 ? undefined : grade,
    klass: klass === 0 ? undefined : klass,
    status,
  });

  useEffect(() => {
    sessionStorage.setItem('PROJECT_PROPOSAL_STATUS', status);
  }, [status]);

  return (
    <div>
      <Typography variant="title1" className="text-primary-gray-900">
        계획서
      </Typography>
      <RadioV2.Group
        selectedValue={status}
        onChange={(value: IBGetSubmissionStatusStatus) => setStatus(value)}
        className="mb-4 flex flex-row gap-4 py-6"
      >
        <RadioV2.Label
          title="미제출"
          currentNum={data?.notSubmitted || 0}
          TotalNum={data?.total || 0}
          value="NOT_SUBMITTED"
          type="number"
        />
        <RadioV2.Label
          title="승인 전"
          currentNum={data?.submitted || 0}
          TotalNum={data?.total || 0}
          value="SUBMITTED"
          type="number"
        />
        <RadioV2.Label
          title="승인 완료"
          currentNum={data?.approved || 0}
          TotalNum={data?.total || 0}
          value="APPROVED"
          type="number"
        />
      </RadioV2.Group>

      {status === 'NOT_SUBMITTED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2" className="text-primary-gray-900">
            계획서 미제출
          </Typography>
          <ActivityPlanOverviewPanel
            title="미제출"
            buttonText="미제출자 알림 보내기"
            buttonHandler={() => alert('미제출자 알림 보내기')}
            data={students}
          />
        </div>
      )}
      {status === 'SUBMITTED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2" className="text-primary-gray-900">
            계획서 승인 전
          </Typography>
          <ActivityPlanOverviewPanel
            title="제출"
            goDetailPage={(studentIbId, proposalId) => push(`/teacher/ib/ee/${studentIbId}/proposal/${proposalId}`)}
            data={students}
            type="SUBMIT"
            buttonText="계획서 전체 다운로드"
            buttonHandler={() => alert('제안서 전체 다운로드')}
          />
          <ActivityPlanOverviewPanel
            title="보완 필요"
            goDetailPage={(studentIbId, proposalId) => push(`/teacher/ib/ee/${studentIbId}/proposal/${proposalId}`)}
            buttonHandler={() => alert('알림 보내기')}
            data={students}
            type="REJECT_PLAN"
          />
        </div>
      )}
      {status === 'APPROVED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2" className="text-primary-gray-900">
            계획서 승인 완료
          </Typography>
          <ActivityPlanOverviewPanel
            goDetailPage={(studentIbId, proposalId) => push(`/teacher/ib/ee/${studentIbId}/proposal/${proposalId}`)}
            title="승인 완료"
            buttonHandler={() => alert('제안서 전체 다운로드')}
            data={students}
          />
        </div>
      )}
    </div>
  );
}
