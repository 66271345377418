import CacheBuster from 'react-cache-buster';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import version from '../package.json';
import { AuthRoute, Blank } from './components/common';
import { HomePage } from './pages/HomePage';
import { AdminMainPage } from './pages/admin/AdminMainPage';
import { IBStudentPage } from './pages/ib/student/IBStudentPage';
import { CoordinatorPreviewPage } from './pages/ib/teacher/coordinator/CoordinatorPreviewPage';
import { MyInfoPage, StudentMainPage } from './pages/student';
import { ConsentForPromotionalAndMarketingPurposes } from './pages/student/ConsentForPromotionalAndMarketingPurposes';
import { ConsentToProvidePersonalInformationToThirdParties } from './pages/student/ConsentToProvidePersonalInformationToThirdParties';
import { ConsentToUseOfPersonalInformation } from './pages/student/ConsentToUseOfPersonalInformation';
import { PrivacyPolicy } from './pages/student/PrivacyPolicy';
import { TermsOfUse } from './pages/student/TermsOfUse';
import { AbsentApprovalPage } from './pages/student/absent/AbsentApprovalPage';
import { FieldtripApprovalPage } from './pages/student/fieldtrip/FieldtripApprovalPage';
import { FieldtripParentNoticePage } from './pages/student/fieldtrip/FieldtripParentNoticePage';
import { FieldtripResultApprovalPage } from './pages/student/fieldtrip/FieldtripResultApprovalPage';
import { AboutSuperSchoolPage } from './pages/student/login/AboutSuperSchool';
import { FirstLoginPage } from './pages/student/login/FirstLoginPage';
import { LoginV2 } from './pages/student/login/LoginPageV2';
import { SelectSchool } from './pages/student/login/SelectSchool';
import { Signup } from './pages/student/login/Signup';
import { TwoFactor } from './pages/student/login/TwoFactor';
import { MyStudyPage } from './pages/student/mypage/MyStudyPage';
import { StudentNewsletterApprovalPage } from './pages/student/newsletter/StudentNewsletterApprovalPage';
import { AddChildrenPage } from './pages/student/parent/AddChildrenPage';
import { ParentSignupPage } from './pages/student/parent/ParentSignupPage';
import { FindIdPageV2 } from './pages/student/password/FindIdPageV2';
import { FindPasswordPageV2 } from './pages/student/password/FindPasswordPageV2';
import { ResetPasswordPageV1 } from './pages/student/password/ResetPasswordPageV1';
import { TeacherMainPage } from './pages/teacher/TeacherMainPage';

export function App() {
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Blank />} //If not pass, nothing appears at the time of new version check.
    >
      <BrowserRouter>
        <Switch>
          <AuthRoute path="/two-factor" component={TwoFactor} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/privacy-policy/:schoolId" component={PrivacyPolicy} />
          <Route path="/reference/preview" component={CoordinatorPreviewPage} />
          <Route path="/consent-to-use-of-personal-information" component={ConsentToUseOfPersonalInformation} />
          <Route
            path="/consent-to-provide-personal-information-to-third-parties"
            component={ConsentToProvidePersonalInformationToThirdParties}
          />
          <Route
            path="/consent-for-promotional-and-marketing-purposes"
            component={ConsentForPromotionalAndMarketingPurposes}
          />
          <Route path="/fieldtrip/result/approve/:uuid" component={FieldtripResultApprovalPage} />
          <Route path="/fieldtrip/approve/:uuid" component={FieldtripApprovalPage} />
          <Route path="/fieldtrip/parent/notice/:uuid" component={FieldtripParentNoticePage} />
          <Route path="/studentnewsletter/approve/:uuid" component={StudentNewsletterApprovalPage} />
          <Route path="/absent/approve/:uuid" component={AbsentApprovalPage} />
          <Route path="/student/info" component={MyInfoPage} />
          <Route path="/student/study" component={MyStudyPage} />
          {/* <Route path="/student/notice/:id" component={NoticeDetailPage} />           */}
          <AuthRoute path="/admin" component={AdminMainPage} />
          <AuthRoute path="/student" component={StudentMainPage} />
          <AuthRoute path="/teacher" component={TeacherMainPage} />
          <AuthRoute path="/ib/student" component={IBStudentPage} />
          <Route path="/reset-password/:id" component={ResetPasswordPageV1} />
          <Route path="/find-password" component={FindPasswordPageV2} />
          <Route path="/find-id" component={FindIdPageV2} />
          <Route path="/AboutSuperSchool" component={AboutSuperSchoolPage} />
          <AuthRoute path="/add-child/:uuid" component={AddChildrenPage} />
          <Route path="/parent-signup" component={ParentSignupPage} />
          <Route path="/first-login" component={FirstLoginPage} />
          <AuthRoute path="/login" guestOnly component={LoginV2} />
          <AuthRoute path="/select-school" guestOnly component={SelectSchool} />
          <AuthRoute path="/signup" guestOnly component={Signup} />
          <Route path="/" component={HomePage} />
        </Switch>
      </BrowserRouter>
    </CacheBuster>
  );
}
