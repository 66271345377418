import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Typography } from 'src/components/common/Typography';
import { ResponseIBPortfolioDto } from 'src/generated/model';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface LearningOutcomeChartProps {
  data?: ResponseIBPortfolioDto;
}

const LearningOutcomeChart = ({ data }: LearningOutcomeChartProps) => {
  const labels = [
    '자신의 장점&성장 분야 개발',
    '도전 과제 수행&새로운 역량 개발',
    'CAS 활동 추진&계획',
    '노력과 인내심',
    '개인의 역량 입증&협력의 이점 인식',
    '세계적 이슈 참여',
    '선택과 행동의 윤리적 측면',
  ];

  const calculateLearningOutcomes = (data?: ResponseIBPortfolioDto) => {
    if (!data?.projects) return Array(labels.length).fill(0);

    const counts = labels.map(() => 0);

    data.projects.forEach((project) => {
      const outcomes = project.cas?.learningOutcome;
      if (outcomes) {
        Object.values(outcomes).forEach((value, index) => {
          if (value) counts[index]++;
        });
      }
    });

    return counts;
  };

  const learningOutcomeCounts = calculateLearningOutcomes(data);
  const hasData = learningOutcomeCounts.some((count) => count > 0);
  const maxDataValue = 8;
  const barWidthPerUnit = maxDataValue > 0 ? 100 / maxDataValue : 0;

  return (
    <div className="relative flex w-full flex-col gap-2">
      {/* 기준선 배경 */}
      <div className="flex w-full flex-row gap-6">
        <div className="w-[192px]"></div>
        <div className="absolute left-[224px] top-2 h-[188px] w-[264px]">
          <div className="relative flex h-full w-full justify-between">
            {Array.from({ length: 9 }, (_, i) => (
              <div className="relative" key={i}>
                {/* 기준선 */}
                <div className="absolute left-0 top-0 h-full w-[1px] -translate-x-1/2 transform bg-primary-gray-100" />
              </div>
            ))}
          </div>
        </div>
      </div>
      {labels.map((label, index) => (
        <div key={index} className="flex w-full flex-row items-center gap-6">
          {/* 라벨 */}
          <Typography variant="body3" className="min-w-[192px]">
            {label}
          </Typography>

          {/* 막대 그래프 */}
          <div className="z-10 ml-2 h-3 w-[264px]">
            <div
              className={`h-full ${
                hasData ? 'rounded-r-[4px] border-dim-8 bg-gradient-navy-400 ' : 'bg-primary-gray-200'
              }`}
              style={{
                width: hasData
                  ? `${learningOutcomeCounts[index] * barWidthPerUnit}%` // 데이터가 있을 경우 계산된 너비
                  : '100%', // 데이터가 없을 경우 전체 너비
              }}
            />
          </div>
        </div>
      ))}
      <div className="flex w-full flex-row gap-6">
        <div className="min-w-[192px]"></div>
        <div className="relative flex w-[280px] flex-row justify-between">
          {Array.from({ length: 9 }, (_, i) => i).map((month) => {
            return (
              <div className="relative w-4 text-center" key={month}>
                <div className="bg-dim-300 absolute left-1/2 top-0 h-full w-px -translate-x-1/2 transform" />
                <Typography variant="caption3" className="text-primary-gray-400">
                  {month}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LearningOutcomeChart;
