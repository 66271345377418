import { PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Blank } from 'src/components/common';
import SelectBar from 'src/components/common/SelectBar';
import { TextareaV2 } from 'src/components/common/TextareaV2';
import { useThemeQuestionFindAll } from 'src/container/ib-themequestion';
import { useExhibitionCreate } from 'src/container/ib-tok-exhibition';
import { RequestExhibitionDto, UploadFileTypeEnum } from 'src/generated/model';
import { useFileUpload } from 'src/hooks/useFileUpload';
import { useImageAndDocument } from 'src/hooks/useImageAndDocument';
import { meState } from 'src/store';
import { ButtonV2 } from '../../common/ButtonV2';
import { Typography } from '../../common/Typography';
import ColorSVGIcon from '../../icon/ColorSVGIcon';
import { ImageCard } from '../ImageCard';

interface IbExhibitionProps {
  modalOpen: boolean;
  setModalClose: () => void;
  size?: 'medium' | 'large';
  handleBack?: () => void;
  onSuccess: (data?: any) => void;
  ibId: number;
  ablePropragation?: boolean;
}

export function IbExhibition({
  modalOpen,
  setModalClose,
  handleBack,
  ibId,
  onSuccess,
  ablePropragation = false,
}: PropsWithChildren<IbExhibitionProps>) {
  const me = useRecoilValue(meState);
  const [wordCounts, setWordCounts] = useState<number[]>([0, 0, 0]);
  const { imageObjectMap, toggleImageDelete, addTargetFiles } = useImageAndDocument({});
  const { isUploadLoading, handleUploadFile } = useFileUpload();

  const handleWordCountChange = (targetKey: number, count: number) => {
    setWordCounts((prev) => {
      const updatedCounts = [...prev];
      updatedCounts[targetKey - 1] = count; // targetKey를 배열 인덱스에 맞게 변환
      return updatedCounts;
    });
  };

  const { createExhibition, isLoading } = useExhibitionCreate({
    onSuccess: () => {
      setModalClose();
      onSuccess();
    },
    onError: (error) => {
      console.error('전시회 생성 중 오류 발생:', error);
    },
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<RequestExhibitionDto>();

  const { data: Questions, isLoading: isFetching } = useThemeQuestionFindAll('TOK_EXHIBITION');

  const transformedOptions =
    Questions?.flatMap((item) =>
      item.questions.map((question, index) => ({
        id: index,
        value: question,
        text: question,
      })),
    ) || [];

  const onSubmit = async (data: RequestExhibitionDto) => {
    if (!me?.id) {
      console.error('Leader ID가 없습니다. 로그인 상태를 확인하세요.');
      return;
    }
    const imageFiles = [...imageObjectMap.values()]
      .filter((value) => !value.isDelete && value.image instanceof File)
      .map((value) => value.image) as File[];

    const uploadedImageUrls = await handleUploadFile(UploadFileTypeEnum['ib/exhibition/images'], imageFiles);
    const existingImageUrls = [...imageObjectMap.values()]
      .filter((value) => !value.isDelete && typeof value.image === 'string')
      .map((value) => value.image) as string[];
    const combinedImageUrls = [...existingImageUrls, ...uploadedImageUrls];

    const targetImageFields = [`targetImage1`, `targetImage2`, `targetImage3`] as const;
    const targetImageUrls = targetImageFields.reduce(
      (acc, field, index) => {
        acc[field] = combinedImageUrls[index] || '';
        return acc;
      },
      {} as Pick<RequestExhibitionDto, 'targetImage1' | 'targetImage2' | 'targetImage3'>,
    );

    const requestData: RequestExhibitionDto = {
      ...data,
      ...targetImageUrls,
      wordCount1: wordCounts[0],
      wordCount2: wordCounts[1],
      wordCount3: wordCounts[2],
    };

    createExhibition({ id: ibId, data: requestData });
  };

  function getImagesByTargetKey(targetKey: number) {
    return [...imageObjectMap.values()].filter((obj) => obj.targetKey === targetKey && !obj.isDelete);
  }

  const themeQuestionValue = watch('themeQuestion');
  const referenceValue = watch('reference');

  return (
    <div
      className={`fixed inset-0 z-60 flex h-screen w-full items-center justify-center bg-black bg-opacity-50 ${
        !modalOpen && 'hidden'
      }`}
      onClick={(e) => {
        if (!ablePropragation) {
          const target = e.target as HTMLElement;
          if (!target.closest('.allow-click')) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      }}
    >
      {(isLoading || isUploadLoading) && <Blank />}
      <div className={`relative w-[848px] overflow-hidden rounded-xl bg-white`}>
        <div className="sticky top-0 z-10 flex h-[88px] items-center justify-between bg-white/70 px-8 pb-6 pt-8 backdrop-blur-[20px]">
          <Typography variant="title1">전시회 작성</Typography>
          <ColorSVGIcon.Close color="gray700" size={32} onClick={setModalClose} className="cursor-pointer" />
        </div>

        <div className="scroll-box flex max-h-[608px] flex-col overflow-auto pb-8 pt-4">
          <div className="flex flex-col gap-3 px-8 pb-8">
            <div className="flex flex-row items-center">
              <Typography variant="title3" className="font-semibold">
                질문 선택
              </Typography>
            </div>
            <SelectBar
              key={themeQuestionValue}
              options={transformedOptions}
              size={40}
              placeholder="질문을 선택하세요"
              dropdownWidth="w-full"
              onChange={(value) => setValue('themeQuestion', value)}
              value={themeQuestionValue}
              // {...register(`themeQuestion` as const)}
            />
          </div>
          <div className="flex flex-col">
            {[1, 2, 3].map((targetKey) => {
              const targetContentField = `targetContent${targetKey}` as keyof RequestExhibitionDto;
              const targetContentValue = watch(targetContentField);

              return (
                <div key={targetKey} className="flex flex-col gap-3 border-t border-t-primary-gray-100 px-8 py-8">
                  <div className="flex flex-row items-center">
                    <Typography variant="title3" className="font-semibold">
                      대상
                    </Typography>
                    &nbsp;
                    <Typography variant="title3" className="font-semibold text-primary-orange-800">
                      {targetKey}
                    </Typography>
                  </div>
                  <div className="relative">
                    <TextareaV2
                      showWordCount={true}
                      onWordCountChange={(count) => handleWordCountChange(targetKey, count)}
                      placeholder="내용을 입력해주세요."
                      className="h-[308px]"
                      readonlyBackground="bg-primary-gray-100"
                      value={targetContentValue}
                      {...register(targetContentField)}
                    />
                    <label
                      htmlFor={`file-upload-${targetKey}`}
                      className="allow-click absolute bottom-4 right-4 z-30 cursor-pointer"
                    >
                      <div className="flex h-8 items-center rounded-md border border-primary-gray-400 px-3 text-[14px] font-medium text-primary-gray-900 active:border-primary-gray-100 active:bg-primary-gray-400 disabled:cursor-not-allowed disabled:border-primary-gray-100 disabled:bg-primary-gray-200 disabled:text-primary-gray-400">
                        이미지 첨부하기
                      </div>
                      <input
                        type="file"
                        id={`file-upload-${targetKey}`}
                        accept="image/*"
                        name="file-upload"
                        className="hidden"
                        onChange={(e) => {
                          e.preventDefault();
                          const files = e.target.files;
                          if (!files) return;
                          addTargetFiles(files, targetKey);
                        }}
                      />
                    </label>
                  </div>
                  {getImagesByTargetKey(targetKey).map((imgObj, idx) => (
                    <ImageCard key={idx} id={idx} imageObjet={imgObj} onDeleteClick={toggleImageDelete} />
                  ))}
                </div>
              );
            })}
          </div>
          <div className="flex flex-col gap-3 border-t border-t-primary-gray-100 px-8 pt-8">
            <div className="flex flex-row items-center">
              <Typography variant="title3" className="font-semibold">
                Reference
              </Typography>
            </div>
            <TextareaV2
              placeholder="내용을 입력해주세요."
              className="h-[308px]"
              readonlyBackground="bg-primary-gray-100"
              value={referenceValue}
              {...register(`reference` as const)}
            />
          </div>
        </div>

        <div
          className={
            'sticky bottom-0 flex h-[104px] justify-between gap-4 border-t border-t-primary-gray-100 bg-white/70 px-8 pb-8 pt-6 backdrop-blur-[20px]'
          }
        >
          <div className="flex flex-row items-center text-12">
            <p className="text-primary-gray-500">총 단어 수</p>&nbsp;
            <p className="font-medium text-primary-orange-800">
              {wordCounts.reduce((total, count) => total + count, 0)}
            </p>
          </div>
          <div className="flex gap-3">
            <ButtonV2
              type="submit"
              variant="solid"
              color="orange800"
              size={48}
              onClick={handleSubmit(onSubmit)}
              disabled={!themeQuestionValue}
            >
              저장하기
            </ButtonV2>
          </div>
        </div>
      </div>
    </div>
  );
}
