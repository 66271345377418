/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type ThemeQuestionGetThemeQuestionsByTypeType =
  (typeof ThemeQuestionGetThemeQuestionsByTypeType)[keyof typeof ThemeQuestionGetThemeQuestionsByTypeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThemeQuestionGetThemeQuestionsByTypeType = {
  TOK_ESSAY: 'TOK_ESSAY',
  TOK_EXHIBITION: 'TOK_EXHIBITION',
} as const;
