import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Blank } from 'src/components/common';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { LayeredTabs, Tab } from 'src/components/common/LayeredTabs';
import { Typography } from 'src/components/common/Typography';
import IBLayout from 'src/components/ib/IBLayout';
import ExhibitionList from 'src/components/ib/tok/ExhibitionList';
import ExhibitionPlanList from 'src/components/ib/tok/ExhibitionPlanList';
import { useIBGetById } from 'src/container/ib-project-get-student';
import { useIBProposalUpdateWaitPlan } from 'src/container/ib-proposal-sent';
import { useexhibitionGetByIBId, useExhibitionPlanSubmit, useExhibitionSubmit } from 'src/container/ib-tok-exhibition';
import { ResponseExhibitionDto } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';

export type TOKProject = 'EXHIBITION' | 'EXHIBITION_PLAN';
export type LocationState = {
  type?: TOKProject;
};

export const ExhibitionMainPage = () => {
  const { t } = useLanguage();
  const { id: idParams } = useParams<{ id: string }>();
  const location = useLocation<LocationState>();
  const initialType = location.state?.type || 'EXHIBITION_PLAN';
  const id = Number(idParams);
  const [type, setType] = useState<TOKProject>(initialType);
  const { data, isLoading, refetch } = useIBGetById(id);

  const { data: Exhibition, isLoading: isFetching } = useexhibitionGetByIBId(id);
  const [alertMessage, setAlertMessage] = useState<{ text: string; action?: () => void } | null>(null);

  const { submitExhibition } = useExhibitionSubmit({
    onSuccess: () => {
      setAlertMessage({ text: `전시회 종료를\n요청하였습니다` });
      refetch();
    },
    onError: (error) => {
      console.error('전시회 기획안 제출 중 오류 발생:', error);
    },
  });

  const { sentIBProposalUpdateWaitPlan, isLoading: isUpdateLoading } = useIBProposalUpdateWaitPlan({
    onSuccess: () => {
      setAlertMessage({ text: `기획안이\n제출되었습니다` });
      refetch();
    },
    onError: (error) => {
      console.error('전시회 기획안 보완제출 중 오류 발생:', error);
    },
  });

  const { submitExhibitionPlan } = useExhibitionPlanSubmit({
    onSuccess: () => {
      setAlertMessage({ text: `기획안이\n제출되었습니다` });
      refetch();
    },
    onError: (error) => {
      console.error('전시회 기획안 제출 중 오류 발생:', error);
    },
  });

  if (data === undefined) return null;
  const canCreate = !['PENDING', 'WAIT_MENTOR', 'REJECT_PLAN'].includes(data?.status);

  const isExhibitionComplete = (exhibition: ResponseExhibitionDto | undefined) => {
    if (!exhibition) return false;

    return (
      !!exhibition.themeQuestion &&
      !!exhibition.targetContent1 &&
      !!exhibition.targetImage1 &&
      !!exhibition.wordCount1 &&
      !!exhibition.targetContent2 &&
      !!exhibition.targetImage2 &&
      !!exhibition.wordCount2 &&
      !!exhibition.targetContent3 &&
      !!exhibition.targetImage3 &&
      !!exhibition.wordCount3 &&
      !!exhibition.reference
    );
  };

  return (
    <div className="col-span-6">
      {isLoading && <Blank />}
      <div className="h-screen w-full">
        <div className="">
          <IBLayout
            topContent={
              <div>
                <div className="w-full pb-6 pt-16">
                  <div className="flex flex-col items-start gap-3">
                    <div className="flex w-full flex-row items-center justify-between">
                      <div className="flex flex-row items-center gap-1">
                        <BadgeV2 color="brown" size={24} type="solid_strong">
                          TOK
                        </BadgeV2>
                        <BadgeV2
                          color={
                            data?.status === 'IN_PROGRESS' ||
                            data?.status === 'WAIT_COMPLETE' ||
                            data?.status === 'WAIT_PLAN_APPROVE'
                              ? 'blue'
                              : data?.status === 'REJECT_MENTOR' ||
                                data?.status === 'REJECT_PLAN' ||
                                data?.status === 'REJECT_COMPLETE'
                              ? 'red'
                              : data?.status === 'COMPLETE'
                              ? 'green'
                              : 'gray'
                          }
                          size={24}
                          type="line"
                        >
                          {data?.status === 'WAIT_PLAN_APPROVE' ? '보완완료' : t(`IBStatus.${data?.status}`)}
                        </BadgeV2>
                      </div>
                      <Breadcrumb
                        data={{
                          진행상태: '/ib/student',
                          'TOK 전시회': `/ib/student/tok/exhibition/${id}`,
                        }}
                      />
                    </div>
                    <Typography variant="heading" className="w-[692px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {data?.title}
                    </Typography>
                  </div>
                </div>
                <LayeredTabs.OneDepth onChange={(selectedType: TOKProject) => setType(selectedType)} value={type}>
                  <Tab value="EXHIBITION_PLAN">
                    <p>기획안</p>
                  </Tab>
                  <Tab value="EXHIBITION">
                    <p>전시회</p>
                  </Tab>
                </LayeredTabs.OneDepth>
              </div>
            }
            bottomContent={
              <div className="flex h-full items-center pt-6">
                <div className="flex w-full flex-col rounded-xl bg-white">
                  {data === undefined ? (
                    <div>제안서를 불러올 수 없습니다.</div>
                  ) : (
                    <>
                      {type === 'EXHIBITION_PLAN' && <ExhibitionPlanList data={data} isLoading={isLoading} />}
                      {type === 'EXHIBITION' && (
                        <ExhibitionList canCreate={canCreate} ibId={id} data={Exhibition} refetch={refetch} />
                      )}
                    </>
                  )}
                </div>
              </div>
            }
            bottomBgColor="bg-primary-gray-50"
            floatingButton={
              (type === 'EXHIBITION_PLAN' &&
                ['PENDING', 'REJECT_PLAN', 'WAIT_PLAN_APPROVE', 'WAITING_FOR_NEXT_PROPOSAL', 'WAIT_MENTOR'].includes(
                  data.status,
                ) &&
                data.tokExhibitionPlan !== undefined && (
                  <div className="flex w-full max-w-[1280px] justify-center">
                    <ButtonV2
                      variant="solid"
                      color="orange800"
                      size={48}
                      className="w-[416px]"
                      disabled={data.status === 'WAIT_MENTOR' || data.status === 'WAIT_PLAN_APPROVE'}
                      onClick={() => {
                        if (data?.tokExhibitionPlan?.id !== undefined) {
                          if (data.status === 'REJECT_PLAN') {
                            sentIBProposalUpdateWaitPlan(data.id);
                          } else {
                            submitExhibitionPlan({ id, exhibitionPlanId: data?.tokExhibitionPlan?.id });
                          }
                        } else {
                          console.error('exhibitionPlanId가 정의되지 않았습니다.');
                        }
                      }}
                    >
                      기획안 승인요청
                    </ButtonV2>
                  </div>
                )) ||
              (Exhibition !== undefined && isExhibitionComplete(Exhibition) && data.status !== 'COMPLETE' && (
                <ButtonV2
                  variant="solid"
                  color="orange800"
                  size={48}
                  className="w-[416px]"
                  disabled={data?.status === 'WAIT_COMPLETE'}
                  onClick={() => {
                    if (Exhibition?.id !== undefined) {
                      submitExhibition({ id: Exhibition.id, ibId: id });
                    } else {
                      console.error('exhibitionPlanId가 정의되지 않았습니다.');
                    }
                  }}
                >
                  전시회 종료 승인요청
                </ButtonV2>
              ))
            }
          />
        </div>
      </div>
      {alertMessage && (
        <AlertV2
          message={alertMessage.text}
          confirmText="확인"
          onConfirm={() => {
            if (alertMessage.action) alertMessage.action();
            setAlertMessage(null);
          }}
        />
      )}
    </div>
  );
};
