import { useState } from 'react';
import NODATA from 'src/assets/images/no-data.png';
import SvgUser from 'src/assets/images/no_profile.png';
import AlertV2 from 'src/components/common/AlertV2';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { Typography } from 'src/components/common/Typography';
import { Constants } from 'src/constants';
import { ResponseIBPortfolioDto, ResponseUserDto } from 'src/generated/model';
import { makeStudNum5 } from 'src/util/status';
import { IbCASProfile } from './IbCASProfile';

type ProfileType = 'create' | 'update';
interface CASProfileProps {
  data?: ResponseIBPortfolioDto;
  refetch: () => void;
  me: ResponseUserDto;
}

function CASProfile({ data, refetch, me }: CASProfileProps) {
  const [profileModal, setProfileModal] = useState<boolean>(false);
  const [type, setType] = useState<ProfileType>('create');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const handleSuccess = () => {
    setAlertMessage(`프로필이\n저장되었습니다`);
    setProfileModal(!profileModal);
    refetch();
  };

  const handleClick = (type: ProfileType) => {
    setProfileModal(!profileModal);
    setType(type);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <Typography variant="title1">내 프로필</Typography>
      </div>
      <div className="flex select-none items-center gap-4 py-2">
        <div className="flex h-12 w-12 overflow-hidden rounded-xl">
          <img
            className="mx-auto h-12 w-12 rounded-xl"
            src={`${Constants.imageUrl}${me?.profile}`}
            alt=""
            loading="lazy"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = SvgUser;
              currentTarget.className = 'w-full';
            }}
          />
        </div>
        <div className="flex h-12 flex-1 flex-col justify-between gap-1">
          <div className="flex items-center">
            <Typography variant="title3">{me?.name.length > 5 ? `${me?.name.slice(0, 5)}` : me?.name}</Typography>
            <span className="mx-1">·</span>
            <Typography variant="title3">
              {makeStudNum5({
                grade: me.groupGrade || 0,
                classNum: me.groupKlass || 0,
                studentNum: me?.studentNumber,
              })}
            </Typography>
          </div>
          {/* TODO : 서버에서 전달받은 코드 사용 */}
          <Typography variant="caption2" className="font-medium text-primary-gray-500">
            응시코드 18472
          </Typography>
        </div>
      </div>
      <span>
        {data === undefined ? (
          <div className="flex flex-col items-center justify-center gap-6 py-20">
            <div className="h-12 w-12 px-[2.50px]">
              <img src={NODATA} className="h-12 w-[43px] object-cover" />
            </div>
            <Typography
              variant="body2"
              className="text-center"
            >{`내 CAS 프로필이 없습니다.\n프로필을 생성해주세요.`}</Typography>
            <ButtonV2 variant="solid" color="orange100" size={40} onClick={() => handleClick('create')}>
              프로필 생성하기
            </ButtonV2>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {data.profile.casInfo?.map((qna) => {
              return (
                <div key={qna.question} className="flex flex-col gap-1">
                  <Typography variant="body3" className="text-primary-gray-500">
                    {qna.question}
                  </Typography>
                  <Typography variant="body3" className="font-medium">
                    {qna.answer}
                  </Typography>
                </div>
              );
            })}
          </div>
        )}
      </span>
      {data && (
        <footer className="mt-auto text-right">
          <ButtonV2 size={40} variant="outline" color="gray400" onClick={() => handleClick('update')}>
            수정
          </ButtonV2>
        </footer>
      )}
      {profileModal && (
        <IbCASProfile
          modalOpen={profileModal}
          type={type}
          profileData={data?.profile}
          setModalClose={() => setProfileModal(!profileModal)}
          onSuccess={handleSuccess}
        />
      )}
      {alertMessage && <AlertV2 message={alertMessage} confirmText="확인" onConfirm={() => setAlertMessage(null)} />}
    </>
  );
}

export default CASProfile;
