import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NODATA from 'src/assets/images/no-data.png';
import { useGetFeedbackBatchExist } from 'src/container/ib-feedback';
import { useRRSGetByIBIdFindAll } from 'src/container/ib-rrs-findAll';
import { FeedbackReferenceTable, ResponseIBDto } from 'src/generated/model';
import { ButtonV2 } from '../../../common/ButtonV2';
import { Typography } from '../../../common/Typography';
import ColorSVGIcon from '../../../icon/ColorSVGIcon';
import FeedbackViewer from '../../FeedbackViewer';
import { LocationState } from 'src/type/ib';

interface RRSListProps {
  id: number;
  data: ResponseIBDto;
  studentData: LocationState['student'];
}

export default function RRSList({ id, data: ibData, studentData }: RRSListProps) {
  const { data = [], refetch } = useRRSGetByIBIdFindAll(id);
  const { push } = useHistory();

  const rrsIds = data.map((rrs) => rrs.id).join(',');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [feedbackReference, setFeedbackReference] = useState<{
    referenceId: number;
    referenceTable: FeedbackReferenceTable;
  }>();
  const [localUnreadCounts, setLocalUnreadCounts] = useState<Record<string, number>>({});

  const { data: feedbacks } = useGetFeedbackBatchExist(
    {
      referenceIds: rrsIds,
      referenceTable: 'RRS',
    },
    { enabled: !!rrsIds },
  );

  useEffect(() => {
    const initialCounts: Record<string, number> = {};
    if (feedbacks?.items) {
      feedbacks.items.forEach((item) => {
        initialCounts[`RRS-${item.referenceId}`] = item.unreadCount || 0;
      });
    }
    setLocalUnreadCounts(initialCounts);
  }, [feedbacks]);

  const markAsRead = (referenceId: number) => {
    const key = `RRS-${referenceId}`;
    setLocalUnreadCounts((prevCounts) => ({
      ...prevCounts,
      [key]: 0,
    }));
  };

  const handleFeedbackOpen = (referenceId: number, referenceTable: FeedbackReferenceTable, unreadCount: number) => {
    setFeedbackReference({ referenceId, referenceTable });
    setFeedbackOpen(true);

    if (unreadCount > 0) {
      markAsRead(referenceId);
    }
  };

  return (
    <section className="h-[664px]">
      <header className="px-6 pb-6">
        <Typography variant="title1" className="text-primary-gray-900">
          RRS
        </Typography>
      </header>
      <main className="flex items-center justify-center">
        {data?.length === 0 ? (
          <div className="flex flex-col items-center justify-center gap-6 py-20">
            <div className="h-12 w-12 px-[2.50px]">
              <img src={NODATA} className="h-12 w-[43px] object-cover" />
            </div>
            <span className="flex flex-col items-center">
              {ibData?.status === 'WAIT_COMPLETE' || ibData?.status === 'COMPLETE' ? (
                <Typography variant="body2" className="font-medium text-primary-gray-900">
                  작성된 RRS가 없습니다.
                </Typography>
              ) : (
                <>
                  <Typography variant="body2" className="font-medium text-primary-gray-900">
                    학생이 RRS를 작성해야
                  </Typography>
                  <Typography variant="body2" className="font-medium text-primary-gray-900">
                    확인할 수 있습니다.
                  </Typography>
                </>
              )}
            </span>
          </div>
        ) : (
          <table className="w-full text-center">
            <thead className="border-y border-y-primary-gray-100 text-[15px] text-primary-gray-500">
              <tr className="flex w-full items-center justify-between gap-[16px] px-[24px] py-[9px] font-medium">
                <th className="w-[68px]">번호</th>
                <th className="w-[740px]">제목</th>
                <th className="w-[200px]">수정일</th>
                <th className="w-[176px]">학생 댓글</th>
              </tr>
            </thead>
            <tbody className="text-[15px] font-medium text-primary-gray-900">
              {data
                ?.slice()
                .reverse()
                .map((rrs, index) => {
                  const feedback = feedbacks?.items?.find((item) => item.referenceId === rrs.id);
                  return (
                    <tr
                      key={rrs.id}
                      className="flex w-full items-center justify-between gap-[16px] border-b border-b-primary-gray-100 px-[24px] py-[9px]"
                    >
                      <td className="w-[68px]">{data.length - index}</td>
                      <td
                        className="line-clamp-1 w-[740px] cursor-pointer"
                        onClick={() =>
                          push(`/teacher/ib/ee/${id}/rrs/${rrs.id}`, {
                            data: ibData,
                            student: studentData,
                          })
                        }
                      >
                        {rrs.title}
                      </td>
                      <td className="w-[200px]">{format(new Date(rrs.createdAt), 'yyyy.MM.dd')}</td>
                      <td className="flex w-[176px] items-center justify-center">
                        {feedback ? (
                          feedback.totalCount === 0 ? (
                            <>-</>
                          ) : localUnreadCounts[`RRS-${rrs.id}`] === 0 ? (
                            <ButtonV2
                              variant="outline"
                              color="gray400"
                              size={32}
                              onClick={() =>
                                push(`/teacher/ib/ee/${id}/rrs/${rrs.id}`, {
                                  data: ibData,
                                  student: studentData,
                                })
                              }
                            >
                              보기
                            </ButtonV2>
                          ) : (
                            <ButtonV2
                              className="flex flex-row items-center gap-1"
                              variant="outline"
                              color="gray400"
                              size={32}
                              onClick={() => handleFeedbackOpen(rrs.id, 'RRS', localUnreadCounts[`RRS-${rrs.id}`])}
                            >
                              <>
                                <ColorSVGIcon.New color="orange800" />
                                보기
                              </>
                            </ButtonV2>
                          )
                        ) : (
                          <>-</>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </main>
      {feedbackOpen && (
        <FeedbackViewer
          modalOpen={feedbackOpen}
          setModalClose={() => setFeedbackOpen(!feedbackOpen)}
          referenceId={feedbackReference?.referenceId || 0}
          referenceTable={feedbackReference?.referenceTable || 'RRS'}
        />
      )}
    </section>
  );
}
