import clsx from 'clsx';
import { format } from 'date-fns';
import { PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIBDeadline } from 'src/container/ib-deadline';
import { useIBTKPPFCreate, useTKPPFGetByIBId } from 'src/container/ib-tok-essay';
import { RequestCreateTKPPFDto, ResponseTKPPFDto } from 'src/generated/model';
import { Blank } from '../../common';
import AlertV2 from '../../common/AlertV2';
import { ButtonV2 } from '../../common/ButtonV2';
import { TextareaV2 } from '../../common/TextareaV2';
import { Typography } from '../../common/Typography';
import ColorSVGIcon from '../../icon/ColorSVGIcon';

interface IbTKPPFProps {
  modalOpen: boolean;
  setModalClose: () => void;
  size?: 'medium' | 'large';
  projectId?: number;
  TKPPFData?: ResponseTKPPFDto;
  onSuccess: () => void;
  ablePropragation?: boolean;
}

export function IbTKPPF({
  modalOpen,
  setModalClose,
  projectId,
  onSuccess,
  TKPPFData,
  ablePropragation = false,
}: PropsWithChildren<IbTKPPFProps>) {
  const { createIBTKPPF, isLoading } = useIBTKPPFCreate({
    onSuccess: () => {
      setModalClose();
      onSuccess();
    },
    onError: (error) => {
      console.error('TKPPF 생성 중 오류 발생:', error);
    },
  });

  const { deadline: deadlineData, isFetching } = useIBDeadline({ type: 'IB_TOK', model: 'TKPPF' });
  const { data: tkppf, isLoading: isRppfLoading } = projectId
    ? useTKPPFGetByIBId(projectId)
    : { data: null, isLoading: false };

  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RequestCreateTKPPFDto>();

  const onSubmit = (data: RequestCreateTKPPFDto) => {
    const filteredData = {
      ...(data.sequence1?.text?.trim() ? { sequence1: data.sequence1 } : {}),
      ...(data.sequence2?.text?.trim() ? { sequence2: data.sequence2 } : {}),
      ...(data.sequence3?.text?.trim() ? { sequence3: data.sequence3 } : {}),
    };

    if (projectId !== undefined) {
      createIBTKPPF({ ibId: projectId, data: filteredData });
    }
  };

  const sequence1Text = watch('sequence1.text');
  const sequence2Text = watch('sequence2.text');
  const sequence3Text = watch('sequence3.text');

  const isTextareaReadonly = (index: number) => {
    // RPPF 작성 모달 Textarea 활성화 조건
    // 1. 기존에 작성한 내용이 있다면 return true => ReadOnly
    // 2. 2차 RPPF 열리는 기간 ===  1차 RPPF 마감기한 < 현재 일자 < 2차 RPPF 마감기한
    // 3. 마감기한이 지났어도 기존에 작성한 내용이 없다면 작성 가능 (2번을 충족하면서)

    const deadline = deadlineData?.sort((a, b) => a.type.localeCompare(b.type)); // 차시순 정렬

    const currentDate = new Date();

    // 기본값 확인
    const defaultValues = [tkppf?.sequence1?.text || '', tkppf?.sequence2?.text || '', tkppf?.sequence3?.text || ''];

    // 기본값이 존재하면 비활성화
    if (defaultValues[index]?.trim() !== '') return true;

    // 마감 기한 데이터가 없는 경우 비활성화
    if (!deadline || !deadline[index]?.deadlineTime) return true;

    // 이전 차수들의 마감 기한 확인
    const previousDeadlinesPassed = deadline.slice(0, index).every((d) => new Date(d.deadlineTime) < currentDate);

    return !previousDeadlinesPassed;
  };

  const isSaveButtonDisabled = [sequence1Text, sequence2Text, sequence3Text].every((text, index) => {
    const defaultValues = [tkppf?.sequence1?.text || '', tkppf?.sequence2?.text || '', tkppf?.sequence3?.text || ''];

    // 텍스트가 비어 있거나 이미 기본값이 존재하면 저장 비활성화
    const isDefaultValue = defaultValues[index]?.trim() !== '';
    const isReadonly = isTextareaReadonly(index);
    return (text?.trim() === '' && !isDefaultValue) || isReadonly;
  });

  return (
    <div
      className={`fixed inset-0 z-60 flex h-screen w-full items-center justify-center bg-black bg-opacity-50 ${
        !modalOpen && 'hidden'
      }`}
      onClick={(e) => {
        if (!ablePropragation) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {(isLoading || isFetching || isRppfLoading) && <Blank />}
      <div className={`relative w-[848px] overflow-hidden rounded-xl bg-white px-8`}>
        <div className="sticky top-0 z-10 flex h-[88px] items-center justify-between bg-white/70 pb-6 pt-8 backdrop-blur-[20px]">
          <Typography variant="title1">TKPPF 작성</Typography>
          <ColorSVGIcon.Close color="gray700" size={32} onClick={setModalClose} className="cursor-pointer" />
        </div>

        <form>
          <div className="scroll-box flex max-h-[608px] flex-col overflow-auto pb-8 pt-4">
            <div className={clsx('flex flex-col gap-3 border-b border-b-primary-gray-100 pb-6')}>
              <div className="flex flex-row items-center justify-between gap-2">
                <Typography variant="title3" className="font-semibold">
                  TKPPF 1차
                </Typography>
                {tkppf?.sequence1 && (
                  <Typography variant="caption" className="text-primary-gray-500">
                    최초 제출일 : {format(new Date(tkppf?.sequence1?.updatedAt), 'yy.MM.dd')}
                  </Typography>
                )}
              </div>
              <TextareaV2
                placeholder="내용을 입력해주세요."
                className="h-[308px]"
                readonlyBackground="bg-primary-gray-100"
                value={tkppf?.sequence1?.text}
                readonly={isTextareaReadonly(0)}
                {...register(`sequence1.text`)}
              />
            </div>
            <div className={clsx('flex flex-col gap-3 border-b border-b-primary-gray-100 py-6')}>
              <div className="flex flex-row items-center justify-between gap-2">
                <Typography variant="title3" className="font-semibold">
                  TKPPF 2차
                </Typography>
                {tkppf?.sequence2 && (
                  <Typography variant="caption" className="text-primary-gray-500">
                    최초 제출일 : {format(new Date(tkppf?.sequence2?.updatedAt), 'yy.MM.dd')}
                  </Typography>
                )}
              </div>
              <TextareaV2
                placeholder="내용을 입력해주세요."
                className="h-[308px]"
                readonlyBackground="bg-primary-gray-100"
                value={tkppf?.sequence2?.text}
                readonly={isTextareaReadonly(1)}
                {...register(`sequence2.text`)}
              />
            </div>
            <div className={clsx('flex flex-col gap-3 pt-6')}>
              <div className="flex flex-row items-center justify-between gap-2">
                <Typography variant="title3" className="font-semibold">
                  TKPPF 3차
                </Typography>
                {tkppf?.sequence3 && (
                  <Typography variant="caption" className="text-primary-gray-500">
                    최초 제출일 : {format(new Date(tkppf?.sequence3?.updatedAt), 'yy.MM.dd')}
                  </Typography>
                )}
              </div>
              <TextareaV2
                placeholder="내용을 입력해주세요."
                className="h-[308px]"
                readonlyBackground="bg-primary-gray-100"
                value={tkppf?.sequence3?.text}
                readonly={isTextareaReadonly(2)}
                {...register(`sequence3.text`)}
              />
            </div>
          </div>

          <div
            className={clsx(
              'sticky bottom-0 flex h-[104px] justify-end gap-4 border-t border-t-primary-gray-100 bg-white/70 pb-8 pt-6 backdrop-blur-[20px]',
            )}
          >
            <ButtonV2
              disabled={isSaveButtonDisabled}
              type="submit"
              variant="solid"
              color="orange100"
              size={48}
              onClick={handleSubmit(onSubmit)}
            >
              저장하기
            </ButtonV2>
          </div>
        </form>
      </div>
      {isOpen && (
        <AlertV2 confirmText="확인" message={`TKPPF가 \n저장되었습니다`} onConfirm={() => setIsOpen(!isOpen)} />
      )}
    </div>
  );
}
