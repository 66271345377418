import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Logo from 'src/assets/images/logo_color.png';
import SvgUser from 'src/assets/images/no_profile.png';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { Typography } from 'src/components/common/Typography';
import ColorSVGIcon from 'src/components/icon/ColorSVGIcon';
import SVGIcon from 'src/components/icon/SVGIcon';
import { Constants } from 'src/constants';
import { meState } from 'src/store';
import { useLogout } from 'src/util/hooks';
import { makeStudNum5 } from 'src/util/status';
import CASInterviewDetailPage from './CAS/CASInterviewDetailPage';
import { CASMainPage } from './CAS/CASMainPage';
import { CASReflectionDiaryDetailPage } from './CAS/CASReflectionDiaryDetailPage';
import { EECreatePage } from './EE/EECreatePage';
import { EeEssayDetailPage } from './EE/EeEssayDetailPage';
import { EEMainPage } from './EE/EEMainPage';
import InterviewDetailPage from './EE/InterviewDetailPage';
import { ProposalDetailPage } from './EE/ProposalDetailPage';
import RPPFDetailPage from './EE/RPPFDetailPage';
import RRSDetailPage from './EE/RRSDetailPage';
import { IBStudentMainPage } from './IBStudentMainPage';
import { IBStudentReferenceDetailPage } from './IBStudentReferenceDetailPage';
import { IBStudentReferencePage } from './IBStudentReferencePage';
import { EssayMainPage } from './TOK_ESSAY/EssayMainPage';
import { OutlineDetailPage } from './TOK_ESSAY/OutlineDetailPage';
import TKPPFDetailPage from './TOK_ESSAY/TKPPFDetailPage';
import { TOKEssayDetailPage } from './TOK_ESSAY/TOKEssayDetailPage';
import TOKRRSDetailPage from './TOK_ESSAY/TOKRRSDetailPage';
import { ExhibitionDetailPage } from './TOK_EXHIBITION/ExhibitionDetailPage';
import { ExhibitionMainPage } from './TOK_EXHIBITION/ExhibitionMainPage';
import { ExhibitionPlanDetailPage } from './TOK_EXHIBITION/ExhibitionPlanDetailPage';

export const IBStudentPage = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const logout = useLogout();
  const me = useRecoilValue(meState);

  if (me == null) {
    return <div>접속 정보를 불러올 수 없습니다.</div>;
  }

  if (!me?.klassGroupName || me?.studentNumber === null) {
    return null; // 데이터가 없거나 studentNumber가 없으면 null 반환
  }

  const match = me?.klassGroupName.match(/(\d)학년 (\d{1,2})반/);
  if (!match) {
    return null;
  }
  const [_, gradeStr, classNumStr] = match;
  const grade = parseInt(gradeStr, 10);
  const classNum = parseInt(classNumStr, 10);

  return (
    <div className="flex">
      <div className="w-[224px] flex-shrink-0">
        <div className="flex h-screen flex-grow flex-col gap-6 border-r border-primary-gray-200 bg-white py-10">
          {/* 로고 영역 */}
          <div className="h-10 w-full px-5">
            <img src={Logo} className="h-10 w-[70px] object-cover" />
          </div>
          {/* 학생 프로필 영역 */}
          <div className="flex select-none items-center gap-3 border-b border-b-primary-gray-100 px-5 pb-6">
            <div className="flex h-12 w-12 overflow-hidden rounded-xl">
              <img
                className="mx-auto h-12 w-12 rounded-xl"
                src={`${Constants.imageUrl}${me?.profile}`}
                alt=""
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = SvgUser;
                  currentTarget.className = 'w-full';
                }}
              />
            </div>
            <div className="flex h-12 flex-1 flex-col justify-between gap-1">
              <div className="flex items-center">
                <Typography variant="title3">{me?.name.length > 5 ? `${me?.name.slice(0, 5)}` : me?.name}</Typography>
                <span className="mx-1">·</span>
                <Typography variant="title3">
                  {makeStudNum5({ grade, classNum, studentNum: me?.studentNumber })}
                </Typography>
              </div>
              {/* TODO : 서버에서 전달받은 코드 사용 */}
              <Typography variant="caption2" className="font-medium text-primary-gray-500">
                응시코드 18472
              </Typography>
            </div>
          </div>
          {/* 메뉴바 */}
          <div className="scroll-box flex h-screen-13 flex-grow flex-col overflow-y-auto px-3 tracking-tighter">
            <nav className={`flex-1`} aria-label="Sidebar">
              <Link
                className="flex flex-row items-center gap-2 px-2 py-4 hover:rounded-lg hover:bg-primary-gray-50"
                to={'/ib/student'}
              >
                <ColorSVGIcon.Project size={24} color={pathname.startsWith('/ib/student') ? 'orange800' : 'gray400'} />
                <Typography
                  variant="title3"
                  className={pathname.startsWith('/ib/student') ? 'text-primary-orange-800' : 'text-primary-gray-400'}
                >
                  프로젝트
                </Typography>
              </Link>
              <div
                className="flex cursor-pointer flex-row items-center gap-2 px-2 py-4 hover:rounded-lg hover:bg-primary-gray-50"
                onClick={() => {
                  window.open(
                    'https://essayreview.co.kr/apa-citation-generator',
                    '_blank',
                    'width=800,height=600,scrollbars=yes',
                  );
                }}
              >
                <ColorSVGIcon.Reference size={24} color="gray400" />
                <Typography variant="title3" className="flex-1">
                  APA 출처생성기
                </Typography>
                <SVGIcon.TopRightFillArrow size={24} color="gray400" />
              </div>
            </nav>
          </div>
          {/* 하단 버튼 영역 */}
          <div className="flex flex-col gap-4 px-5">
            <ButtonV2 color="gray100" size={40} variant="solid" onClick={() => push('/student')}>
              슈퍼스쿨 바로가기
            </ButtonV2>
            <div className="flex w-full items-center">
              <Typography
                variant="caption"
                className="w-1/2 cursor-pointer text-center text-primary-gray-600"
                onClick={() => alert('내정보 관리')}
              >
                내정보 관리
              </Typography>
              <div className="h-3 w-px bg-[#e8eaec]" />
              <Typography
                variant="body3"
                className="w-1/2 cursor-pointer text-center text-[13px] text-primary-gray-600"
                onClick={logout}
              >
                로그아웃
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-box h-screen w-full grid-cols-6 overflow-x-hidden overflow-y-scroll md:grid md:overflow-y-hidden">
        <Switch>
          <Route path="/ib/student/ee/:id/proposal/:proposalId" component={ProposalDetailPage} />
          <Route path="/ib/student/ee/:id/essay/:essayId" component={EeEssayDetailPage} />
          <Route path="/ib/student/ee/:id/rppf/:rppfId" component={RPPFDetailPage} />
          <Route path="/ib/student/ee/:id/interview/:qnaId" component={InterviewDetailPage} />
          <Route path="/ib/student/ee/:id/rrs/:rrsId" component={RRSDetailPage} />
          <Route path="/ib/student/ee/create" component={EECreatePage} />
          <Route path="/ib/student/ee/:id" component={EEMainPage} />
          <Route path="/ib/student/cas/:id" component={CASMainPage} />
          <Route path="/ib/student/tok/exhibition/:id/detail/:exhibitionId" component={ExhibitionDetailPage} />
          <Route path="/ib/student/tok/exhibition/plan/:id" component={ExhibitionPlanDetailPage} />
          <Route path="/ib/student/tok/exhibition/:id" component={ExhibitionMainPage} />
          <Route path="/ib/student/tok/essay/outline/:id" component={OutlineDetailPage} />
          <Route path="/ib/student/tok/essay/detail/:id" component={TOKEssayDetailPage} />
          <Route path="/ib/student/tok/essay/:id/rrs/:rrsId" component={TOKRRSDetailPage} />
          <Route path="/ib/student/tok/essay/:id/tkppf/:tkppfId" component={TKPPFDetailPage} />
          <Route path="/ib/student/tok/essay/:id" component={EssayMainPage} />
          <Route path="/ib/student/reference/:id" component={IBStudentReferenceDetailPage} />
          <Route path="/ib/student/reference" component={IBStudentReferencePage} />
          <Route path="/ib/student/portfolio/reflection-diary/:id" component={CASReflectionDiaryDetailPage} />
          <Route path="/ib/student/portfolio/interview/:id" component={CASInterviewDetailPage} />
          <Route path="/ib/student" component={IBStudentMainPage} />
        </Switch>
      </div>
    </div>
  );
};
