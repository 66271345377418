import clsx from 'clsx';
import { ChangeEvent, TextareaHTMLAttributes, forwardRef, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  showWordCount?: boolean;
  onWordCountChange?: (count: number) => void;
  readonly?: boolean;
  disabled?: boolean;
  readonlyBackground?: 'bg-white' | 'bg-primary-gray-100';
  wrapperClassName?: string;
  textareaClassName?: string;
}

export const TextareaV2 = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  {
    className,
    value,
    showWordCount,
    onChange,
    onWordCountChange,
    disabled = false,
    readonly = false,
    readonlyBackground = 'bg-white',
    wrapperClassName,
    textareaClassName,

    ...props
  },
  ref,
) {
  const [inputValue, setInputValue] = useState(value || '');
  useEffect(() => {
    if (inputValue !== value) setInputValue(value || ''); // 외부 value와 동기화
  }, [value]);

  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value.trim();
    if (showWordCount && onWordCountChange) {
      const words = text ? text.split(/\s+/).filter((word) => word.length > 0) : [];
      onWordCountChange(words.length);
    }
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div
      className={twMerge(
        clsx(
          className,
          'relative flex flex-col justify-between gap-4 rounded-lg border border-primary-gray-200 p-4 focus:outline-none focus:ring-0',
          {
            'bg-primary-gray-100': (readonly && readonlyBackground === 'bg-primary-gray-100') || disabled,
            'cursor-not-allowed': readonly || disabled,
            'cursor-pointer': !readonly && !disabled,
            'border-primary-gray-700': isFocused,
            [readonlyBackground]: readonly && readonlyBackground,
          },
        ),
        wrapperClassName,
      )}
      onFocus={!readonly && !disabled ? handleFocus : undefined}
      onBlur={!readonly && !disabled ? handleBlur : undefined}
    >
      <textarea
        ref={ref}
        className={twMerge(
          clsx(
            `h-full min-h-max w-full resize-none border-none p-0 text-15 font-medium text-primary-gray-900 placeholder-primary-gray-400 caret-primary-blue-800 read-only:pointer-events-none focus:text-primary-gray-700 focus:outline-none focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400`,
            {
              'bg-primary-gray-100': (readonly && readonlyBackground === 'bg-primary-gray-100') || disabled,
              'cursor-not-allowed': readonly || disabled,
              'cursor-pointer': !readonly && !disabled,
              [readonlyBackground]: readonly && readonlyBackground,
            },
          ),
          textareaClassName,
        )}
        readOnly={readonly}
        onChange={handleInputChange}
        disabled={disabled}
        value={inputValue}
        {...props}
      />
      {showWordCount && (
        <div className="flex flex-row items-center text-12">
          <p className="text-primary-gray-500">단어 수</p>&nbsp;
          <p className="font-medium text-primary-orange-800">
            <p className="font-medium text-primary-orange-800">
              {typeof inputValue === 'string' && inputValue.trim()
                ? inputValue
                    .trim()
                    .split(/\s+/)
                    .filter((word) => word.length > 0).length
                : 0}
            </p>
          </p>
        </div>
      )}
    </div>
  );
});
