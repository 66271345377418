import { useThemeQuestionGetThemeQuestionItemsByType } from 'src/generated/endpoint';
import { ThemeQuestionGetThemeQuestionsByTypeType } from 'src/generated/model';
export const useThemeQuestionFindAll = (type: ThemeQuestionGetThemeQuestionsByTypeType) => {
  const { data, isLoading, refetch } = useThemeQuestionGetThemeQuestionItemsByType({ type });

  return {
    data,
    isLoading,
    refetch,
  };
};
