import { useState } from 'react';
import { useIBCasPortfolioGetItems } from 'src/generated/endpoint';
import { IBCasPortfolioGetItemsParams } from 'src/generated/model';

export function useGetIBPortfolio() {
  const [param, setParam] = useState<IBCasPortfolioGetItemsParams>();

  const { data, isLoading } = useIBCasPortfolioGetItems(param, {
    query: {
      // enabled: !!param?.statuses,
    },
  });

  const getIBPortfolio = ({ grade, klass, limit, page }: IBCasPortfolioGetItemsParams) => {
    setParam({ grade, klass, limit, page });
  };

  return {
    data,
    getIBPortfolio,
    isLoading,
  };
}
