import { map, max, orderBy } from 'lodash';
import { useState } from 'react';
import { useEEEvaluationGetItems } from 'src/generated/endpoint';
import { ResponseEvaluationDto } from 'src/generated/model';
import { DateFormat, DateUtil } from 'src/util/date';
import AlertV2 from '../../../common/AlertV2';
import { ButtonV2 } from '../../../common/ButtonV2';
import { Typography } from '../../../common/Typography';
import FrontPaginatedList from '../../../FrontPaginatedList ';
import { CoordinatorEE_Eval_AddEval } from './CoordinatorEE_Eval_AddEval';
import { CoordinatorEE_Eval_UpdateEval } from './CoordinatorEE_Eval_UpdateEval';

export type ModalType = 'Update' | 'Add' | null;
export type CategoryType = 'Ref' | 'FAQ' | '';

export default function CoordinatorEE_Eval() {
  const { data } = useEEEvaluationGetItems({ location: 'ESSAY' });

  const items = orderBy(data?.items || [], 'id', 'desc');

  const lastId = max(map(items, 'id'));

  const [activeModal, setActiveModal] = useState<ModalType>(null);
  const [selectedEval, setSelectedEval] = useState<ResponseEvaluationDto>();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [viewType, setViewType] = useState<'VIEW' | 'UPDATE'>('UPDATE');

  const Header = () => (
    <>
      <div className="flex-[1] px-2 text-center">번호</div>
      <div className="flex-[10] px-2 text-center">제목</div>
      <div className="flex-[2] px-2 text-center">작성일</div>
      <div className="flex-[2] px-2 text-center">관리</div>
    </>
  );

  // Item 컴포넌트
  const Item = ({ item }: { item: any }) => (
    <>
      <div className="flex-[1] p-2 text-center">{item.id}</div>
      <div className="flex-[10] p-2 text-left">{item.title}</div>
      <div className="flex-[2] p-2 text-center">{DateUtil.formatDate(item.createdAt, DateFormat['YYYY.MM.DD'])}</div>
      <div className="flex-[2] p-2 text-center">
        <ButtonV2
          variant="outline"
          size={32}
          color="gray400"
          onClick={(e) => {
            e.stopPropagation();
            if (item.id !== lastId) {
              setViewType('VIEW');
              handleEditClick(item);
            } else {
              setViewType('UPDATE');
              handleEditClick(item);
            }
          }}
        >
          수정
        </ButtonV2>
      </div>
    </>
  );

  const handleEditClick = (item: any) => {
    setSelectedEval(item);
    setActiveModal('Update');
  };

  return (
    <div className="h-[664px] rounded-xl bg-white">
      <div className="flex flex-row items-center justify-between p-6">
        <Typography variant="title1">평가</Typography>
        <ButtonV2 variant="solid" size={40} color="orange800" onClick={() => setActiveModal('Add')}>
          추가하기
        </ButtonV2>
      </div>

      <FrontPaginatedList
        headerComponent={<Header />}
        itemComponent={(item) => <Item item={item} />}
        page={1}
        pageSize={20}
        totalItems={items.length}
        items={items}
        onSelect={(item) => console.log('선택된 항목:', item)}
      />
      <CoordinatorEE_Eval_AddEval
        modalOpen={activeModal === 'Add'}
        setModalClose={() => setActiveModal(null)}
        onSuccess={() => setActiveModal(null)}
      />
      {selectedEval && (
        <CoordinatorEE_Eval_UpdateEval
          viewType={viewType}
          modalOpen={activeModal === 'Update'}
          setModalClose={() => setActiveModal(null)}
          onSuccess={() => {
            setActiveModal(null);
          }}
          evaluationData={selectedEval}
        />
      )}
      {alertMessage && (
        <AlertV2
          message={alertMessage}
          confirmText="확인"
          onConfirm={() => setAlertMessage(null)} // closes the AlertV2 by resetting the message
        />
      )}
    </div>
  );
}
