import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import NODATA from 'src/assets/images/no-data.png';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { Input } from 'src/components/common/Input';
import { LayeredTabs, Tab } from 'src/components/common/LayeredTabs';
import { Typography } from 'src/components/common/Typography';
import { Feedback } from 'src/components/ib/Feedback';
import IBLayout from 'src/components/ib/IBLayout';
import { IbEssay } from 'src/components/ib/tok/IbEssay';
import { useEssayGetByIBId } from 'src/container/ib-essay-find';
import { useIBEssaySent } from 'src/container/ib-essay-send';
import { ResponseIBDto } from 'src/generated/model';
import { meState } from 'src/store';
import { getUrlFromFile, handleDownload } from 'src/util/file';

interface LocationState {
  project: ResponseIBDto;
  type?: string;
}

export const TOKEssayDetailPage = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const project = location.state?.project;
  const _type = location.state?.type;
  const me = useRecoilValue(meState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const { data: essay, refetch, isLoading } = useEssayGetByIBId(project.id);
  const [modalType, setModalType] = useState<'update' | 'update_check'>('update');
  const [type, setType] = useState<string>(_type || 'checklist');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { sentIBEssay } = useIBEssaySent({
    onSuccess: () => {
      setAlertMessage(`에세이가\n제출되었습니다`);
    },
    onError: (error) => {
      console.error('IB 프로젝트 생성 중 오류 발생:', error);
    },
  });

  const handleChange = (selectedType: string) => {
    setType(selectedType);
  };

  const handleSuccess = (action: 'update' | 'update_check' | 'create') => {
    setIsOpen(!isOpen);
    refetch();
    setAlertMessage(action === 'update' ? `에세이가 \n저장되었습니다` : `체크리스트가\n수정되었습니다`);
  };

  if (me == null) {
    return <div>접속 정보를 불러올 수 없습니다.</div>;
  }

  if (essay == undefined) {
    return <div>에세이 정보를 불러올 수 없습니다.</div>;
  }

  return (
    <div className="col-span-6">
      <IBLayout
        topContent={
          <div>
            <div className="w-full pb-6 pt-16">
              <div className="flex flex-col items-start gap-3">
                <div className="flex w-full flex-row items-center justify-between">
                  <div className="flex flex-row gap-1">
                    <BadgeV2 color="brown" size={24} type="solid_strong">
                      TOK
                    </BadgeV2>
                    <BadgeV2 color="gray" size={24} type="solid_regular">
                      에세이
                    </BadgeV2>
                  </div>
                  <Breadcrumb
                    data={{
                      진행상태: '/ib/student',
                      'TOK 에세이': `/ib/student/tok/essay/${project.id}`,
                      '에세이 상세': `/ib/student/tok/${project.id}/essay/${essay.id}`,
                    }}
                  />
                </div>

                <Typography variant="heading" className="w-[692px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {project?.tokOutline?.themeQuestion}
                </Typography>
              </div>
            </div>
          </div>
        }
        bottomContent={
          <div className="flex flex-grow flex-col">
            <div className="flex h-full flex-row gap-4 py-6">
              <div className="flex h-[812px] w-[848px] flex-col justify-between gap-6 rounded-xl bg-white p-6">
                <div className="h-full w-full">
                  <iframe
                    src={getUrlFromFile(essay.filePath)}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    title="PDF Viewer"
                  />
                </div>
                <footer className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center gap-2">
                    <ButtonV2
                      size={40}
                      variant="outline"
                      color="gray400"
                      onClick={() => {
                        handleDownload(getUrlFromFile(essay.filePath));
                      }}
                    >
                      다운로드
                    </ButtonV2>
                    {
                      <ButtonV2
                        size={40}
                        variant="outline"
                        color="gray400"
                        disabled={project.status === 'WAIT_COMPLETE' || project.status === 'COMPLETE'}
                        onClick={() => {
                          setModalType('update');
                          setIsOpen(!isOpen);
                        }}
                      >
                        에세이 재업로드
                      </ButtonV2>
                    }
                  </div>
                  <ButtonV2
                    size={40}
                    variant="solid"
                    color="gray100"
                    onClick={() => history.push(`/ib/student/tok/essay/${project.id}`, { type: 'ESSAY' })}
                  >
                    목록 돌아가기
                  </ButtonV2>
                </footer>
              </div>
              <div className="flex h-[720px] w-[416px] flex-col gap-6 rounded-xl bg-white p-6">
                <LayeredTabs.TwoDepth onChange={handleChange} value={type} fullWidth={true}>
                  <Tab value="feedback">
                    <p>진행기록</p>
                  </Tab>
                  <Tab value="checklist">
                    <p>체크리스트</p>
                  </Tab>
                </LayeredTabs.TwoDepth>
                {type === 'feedback' ? (
                  <div className="h-full w-full">
                    {essay.status === 'PENDING' ? (
                      <div className="flex flex-col items-center gap-6 py-20">
                        <div className="h-12 w-12 px-[2.50px]">
                          <img src={NODATA} className="h-12 w-[43px] object-cover" />
                        </div>
                        <Typography variant="body2">진행기록이 없습니다.</Typography>
                      </div>
                    ) : (
                      <Feedback referenceId={essay.id} referenceTable="ESSAY" user={me} />
                    )}
                  </div>
                ) : (
                  <div className="flex h-full w-full flex-col justify-between">
                    <div className="flex flex-row items-center justify-between rounded-lg">
                      <Typography variant="body3">사용 단어 수</Typography>
                      <Input.Basic size={32} type="number" value={essay.charCount} readonly />
                    </div>
                    {
                      <div className="flex items-center justify-end">
                        <ButtonV2
                          size={40}
                          variant="outline"
                          color="gray400"
                          disabled={project.status === 'WAIT_COMPLETE' || project.status === 'COMPLETE'}
                          onClick={() => {
                            setModalType('update_check');
                            setIsOpen(!isOpen);
                          }}
                        >
                          수정
                        </ButtonV2>
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        }
        floatingButton={
          <ButtonV2
            variant="solid"
            color="orange800"
            size={48}
            className="w-[416px]"
            onClick={() => sentIBEssay(essay.id)}
            disabled={essay?.status === 'SUBMIT'}
          >
            에세이 제출
          </ButtonV2>
        }
        bottomBgColor="bg-primary-gray-50"
      />
      {isOpen && (
        <IbEssay
          modalOpen={isOpen}
          setModalClose={() => setIsOpen(!isOpen)}
          type={modalType}
          projectId={project.id}
          onSuccess={handleSuccess}
          essayData={essay}
        />
      )}

      {alertMessage && <AlertV2 message={alertMessage} confirmText="확인" onConfirm={() => setAlertMessage(null)} />}
    </div>
  );
};
