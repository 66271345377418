import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Blank } from 'src/components/common';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { LayeredTabs, Tab } from 'src/components/common/LayeredTabs';
import { TextareaV2 } from 'src/components/common/TextareaV2';
import { Typography } from 'src/components/common/Typography';
import IBLayout from 'src/components/ib/IBLayout';
import ExhibitionList from 'src/components/ib/tok/teacher/ExhibitionList';
import ExhibitionPlanList from 'src/components/ib/tok/teacher/ExhibitionPlanList';
import SolidSVGIcon from 'src/components/icon/SolidSVGIcon';
import { PopupModal } from 'src/components/PopupModal';
import { useGetTokEvaluationInitialData } from 'src/container/ib-evaluation';
import { useIBApproveComplete, useIBStatusRejectComplete } from 'src/container/ib-project';
import { useIBGetById } from 'src/container/ib-project-get-student';
import { useIBExhibitionPlanStatusApprove, useIBExhibitionPlanStatusReject } from 'src/container/ib-tok-exhibition';
import { useLanguage } from 'src/hooks/useLanguage';

export type TOKProject = 'EXHIBITION' | 'EXHIBITION_PLAN';
export type LocationState = {
  type?: TOKProject;
};

export const ExhibitionMainPage = () => {
  const { t } = useLanguage();
  const { ibId: idParams } = useParams<{ ibId: string }>();
  const location = useLocation<LocationState>();
  const initialType = location.state?.type || 'EXHIBITION_PLAN';
  const id = Number(idParams);
  const [type, setType] = useState<TOKProject>(initialType);
  const { data, klassNum, isLoading, refetch } = useIBGetById(id);

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [rejectPlanModalOpen, setRejectPlanModalOpen] = useState(false); // 기획안 보완 요청 Modal
  const [rejectPlanReason, setRejectPlanReason] = useState(''); // 기획안 보완 요청 피드백

  const [rejectExhibitionConfirmModalOpen, setRejectExhibitionConfirmModalOpen] = useState(false); // 전시회 종료 요청 반려 Modal
  const [approveExhibitionConfirmModalOpen, setApproveExhibitionConfirmModalOpen] = useState(false); // 전시회 종료 요청 승인 Modal

  // 기획안 반려 api 호출 (보완요청 버튼 클릭 시 실행)
  const { rejectExhibitionPlan } = useIBExhibitionPlanStatusReject({
    onSuccess: () => {
      setRejectPlanModalOpen(false);
      setAlertMessage(`기획안 보완을\n요청하였습니다`);
      refetch();
    },
  });

  // 기획안 승인 api 호출
  const { approveExhibitionPlan } = useIBExhibitionPlanStatusApprove({
    onSuccess: () => {
      setAlertMessage(`기획안이\n승인되었습니다`);
      refetch();
    },
  });

  // 완료 승인 대기 중일 때 평가 조회 api 호출
  const { data: evaluation } = useGetTokEvaluationInitialData(
    { ibId: id || 0, type: 'EXHIBITION' },
    {
      enabled: data?.status === 'WAIT_COMPLETE',
    },
  );

  // 최종 평가 점수
  const evaluationScore = evaluation?.evaluations?.find((evaluation) => evaluation.isFinal)?.score ?? null;

  // 전시회 종료 반려 api 호출 (프로젝트 완료 반려)
  const { rejectIBProjectComplete } = useIBStatusRejectComplete({
    onSuccess: () => {
      setAlertMessage(`전시회 종료 요청을\n반려하였습니다`);
      refetch();
    },
  });

  // 전시회 종료 승인 api 호출
  const { approveIBProjectComplete } = useIBApproveComplete({
    onSuccess: () => {
      setAlertMessage(`전시회 종료를\n승인하였습니다`);
      refetch();
    },
  });

  const acceptExhibitionTitle =
    data?.status === 'IN_PROGRESS' ||
    data?.status === 'WAIT_COMPLETE' ||
    data?.status === 'COMPLETE' ||
    data?.status === 'REJECT_COMPLETE'
      ? data.tokExhibitionPlan?.themeQuestion
      : `${data?.leader.name}의 TOK 전시회`;

  return (
    <div className="col-span-6">
      {isLoading && <Blank />}
      <div className="h-screen w-full">
        <div className="">
          <IBLayout
            topContent={
              <div>
                <div className="w-full pb-6 pt-16">
                  <div className="flex flex-col items-start gap-3">
                    <div className="flex w-full flex-row items-center justify-between">
                      <div className="flex flex-row items-center gap-1">
                        <BadgeV2 color="brown" size={24} type="solid_strong">
                          TOK
                        </BadgeV2>
                        <BadgeV2
                          color={
                            data?.status === 'IN_PROGRESS' ||
                            data?.status === 'WAIT_COMPLETE' ||
                            data?.status === 'WAIT_PLAN_APPROVE'
                              ? 'blue'
                              : data?.status === 'REJECT_MENTOR' ||
                                data?.status === 'REJECT_PLAN' ||
                                data?.status === 'REJECT_COMPLETE'
                              ? 'red'
                              : data?.status === 'COMPLETE'
                              ? 'green'
                              : 'gray'
                          }
                          size={24}
                          type="line"
                        >
                          {data?.status === 'WAIT_PLAN_APPROVE' ? '보완완료' : t(`IBStatus.${data?.status}`)}
                        </BadgeV2>
                      </div>
                      <Breadcrumb
                        data={{
                          진행상태: '/teacher/project',
                          'TOK 전시회': `/teacher/ib/tok/exhibition/${id}`,
                        }}
                      />
                    </div>
                    <div className="flex w-full items-start justify-between">
                      <Typography
                        variant="heading"
                        className="w-[692px] overflow-hidden text-ellipsis whitespace-nowrap text-primary-gray-900"
                      >
                        {acceptExhibitionTitle}
                      </Typography>
                      <div className="rounded-lg border border-orange-100 bg-orange-50 px-4 py-2 text-16 font-semibold text-primary-orange-800">
                        {klassNum} · {data?.leader.name}
                      </div>
                    </div>
                  </div>
                </div>
                <LayeredTabs.OneDepth
                  onChange={(selectedType: TOKProject) => setType(selectedType)}
                  value={type}
                  inActiveClassName="text-primary-gray-900 border-primary-gray-900"
                >
                  <Tab value="EXHIBITION_PLAN">
                    <p>기획안</p>
                  </Tab>
                  <Tab value="EXHIBITION">
                    <p>전시회</p>
                  </Tab>
                </LayeredTabs.OneDepth>
              </div>
            }
            bottomContent={
              <div className="flex h-full items-center pt-6">
                <div className="flex w-full flex-col rounded-xl bg-white">
                  {data === undefined ? (
                    <div>기획안을 불러올 수 없습니다.</div>
                  ) : (
                    <>
                      {type === 'EXHIBITION_PLAN' && (
                        <ExhibitionPlanList data={data} refetch={refetch} isLoading={isLoading} />
                      )}
                      {type === 'EXHIBITION' && <ExhibitionList data={data} refetch={refetch} />}
                    </>
                  )}
                </div>
              </div>
            }
            bottomBgColor="bg-primary-gray-50"
            floatingButton={
              (type === 'EXHIBITION_PLAN' &&
                (data?.status === 'WAIT_MENTOR' || data?.status === 'WAIT_PLAN_APPROVE') && (
                  <div>
                    <div className="mx-auto flex w-[1280px] items-center justify-end">
                      <div className="flex items-center gap-4">
                        <ButtonV2
                          className="w-[200px]"
                          size={48}
                          variant="solid"
                          color="gray700"
                          onClick={() => setRejectPlanModalOpen(true)}
                        >
                          기획안 보완요청
                        </ButtonV2>
                        <ButtonV2
                          className="w-[200px]"
                          size={48}
                          variant="solid"
                          color="orange800"
                          onClick={() => {
                            approveExhibitionPlan(Number(id), Number(data.tokExhibitionPlan?.id));
                          }}
                        >
                          기획안 승인
                        </ButtonV2>
                      </div>
                    </div>
                  </div>
                )) ||
              (type === 'EXHIBITION_PLAN' && data?.status === 'IN_PROGRESS' && (
                <div>
                  <div className="mx-auto flex w-[1280px] items-center justify-center">
                    <ButtonV2 className="w-[416px]" size={48} variant="solid" color="orange800" disabled>
                      기획안 승인
                    </ButtonV2>
                  </div>
                </div>
              )) ||
              (type === 'EXHIBITION_PLAN' && data?.status === 'REJECT_PLAN' && (
                <div>
                  <div className="mx-auto flex w-[1280px] items-center justify-center">
                    <ButtonV2 className="w-[416px]" size={48} variant="solid" color="gray700" disabled>
                      기획안 보완요청
                    </ButtonV2>
                  </div>
                </div>
              )) ||
              (data?.status === 'WAIT_COMPLETE' && (
                <div>
                  <div className="mx-auto flex w-[1280px] items-center justify-between">
                    <Typography variant="caption2" className="flex items-center gap-1 text-primary-gray-400">
                      <SolidSVGIcon.Info color="gray400" size={16} />
                      전시회 평가 완료 후 전시회 종료 승인이 가능합니다.
                    </Typography>
                    <div className="flex items-center gap-4">
                      <ButtonV2
                        className="w-[200px]"
                        size={48}
                        variant="solid"
                        color="gray700"
                        onClick={() => setRejectExhibitionConfirmModalOpen(true)}
                      >
                        전시회 종료 반려
                      </ButtonV2>
                      <ButtonV2
                        className="w-[200px]"
                        size={48}
                        variant="solid"
                        color="orange800"
                        disabled={evaluationScore === null}
                        onClick={() => {
                          setApproveExhibitionConfirmModalOpen(true);
                        }}
                      >
                        전시회 종료 승인
                      </ButtonV2>
                    </div>
                  </div>
                </div>
              )) ||
              (data?.status === 'REJECT_COMPLETE' && (
                <div>
                  <div className="mx-auto flex w-[1280px] items-center justify-center">
                    <ButtonV2 className="w-[416px]" size={48} variant="solid" color="gray700" disabled>
                      전시회 종료 반려
                    </ButtonV2>
                  </div>
                </div>
              )) ||
              (data?.status === 'COMPLETE' && (
                <div>
                  <div className="mx-auto flex w-[1280px] items-center justify-center">
                    <ButtonV2 className="w-[416px]" size={48} variant="solid" color="gray700" disabled>
                      전시회 종료 승인
                    </ButtonV2>
                  </div>
                </div>
              ))
            }
          />
        </div>
      </div>

      {/* 기획안 보완 요청 Modal */}
      <PopupModal
        modalOpen={rejectPlanModalOpen}
        setModalClose={() => {
          setRejectPlanModalOpen(false);
          setRejectPlanReason('');
        }}
        title="기획안 보완 요청"
        bottomBorder={false}
        footerButtons={
          <ButtonV2
            size={48}
            variant="solid"
            color="orange800"
            className="w-[88px]"
            disabled={!Boolean(rejectPlanReason.length).valueOf()}
            onClick={() => rejectExhibitionPlan(Number(id), data!.tokExhibitionPlan!.id, { content: rejectPlanReason })}
          >
            전달하기
          </ButtonV2>
        }
      >
        <div className="flex flex-col gap-6">
          <Typography variant="body1">학생에게 기획안에 대한 피드백을 남겨주세요.</Typography>
          <TextareaV2
            className="h-40 resize-none rounded-lg p-4"
            placeholder="내용을 입력해주세요."
            value={rejectPlanReason}
            onChange={(e) => setRejectPlanReason(e.target.value)}
          />
        </div>
      </PopupModal>

      {/* 전시회 종료 반려 Confirm Modal */}
      {rejectExhibitionConfirmModalOpen && (
        <AlertV2
          message={`전시회 종료 요청을 반려하시겠습니까?`}
          description={'보완이 필요한 진행기록에 보완사유를 남겨주세요.'}
          confirmText="확인"
          cancelText="취소"
          onConfirm={() => {
            rejectIBProjectComplete(Number(id), {});
            setRejectExhibitionConfirmModalOpen(false);
          }}
          onCancel={() => setRejectExhibitionConfirmModalOpen(false)}
        />
      )}

      {/* 전시회 종료 승인 Confirm Modal */}
      {approveExhibitionConfirmModalOpen && (
        <AlertV2
          message={`전시회 종료를 승인하시겠습니까?`}
          description={'승인 시 학생의 활동이 완전히 종료되며\n학생의 제출물과 평가 및 지도의견 수정이 불가합니다.'}
          confirmText="확인"
          cancelText="취소"
          onConfirm={() => {
            approveIBProjectComplete(Number(id));
            setApproveExhibitionConfirmModalOpen(false);
          }}
          onCancel={() => setApproveExhibitionConfirmModalOpen(false)}
        />
      )}

      {alertMessage && <AlertV2 message={alertMessage} confirmText="확인" onConfirm={() => setAlertMessage(null)} />}
    </div>
  );
};
