import {
  useEssayGetSubmissionStatus,
  useEssayGetSubmissionStatusCount,
  useExhibitionGetSubmissionStatus,
  useExhibitionGetSubmissionStatusCount,
  useIBCasPortfolioGetSubmissionStatusByInterview,
  useIBCasPortfolioGetSubmissionStatusByInterviewCount,
  useIBCasPortfolioGetSubmissionStatusByReflectionDiary,
  useIBCasPortfolioGetSubmissionStatusByReflectionDiaryCount,
  useIBGetSubmissionStatus,
  useIBGetSubmissionStatusCount,
  useIBGetTokExhibitionPlanSubmissionStatus,
  useIBGetTokExhibitionPlanSubmissionStatusCount,
  useIBGetTokOutlineSubmissionStatus,
  useIBGetTokOutlineSubmissionStatusCount,
  useRPPFGetSubmissionStatus,
  useRPPFGetSubmissionStatusCount,
  useRRSGetSubmissionStatus,
  useRRSGetSubmissionStatusCount,
  useTKPPFGetSubmissionStatus,
  useTKPPFGetSubmissionStatusCount,
} from 'src/generated/endpoint';
import {
  EssayGetSubmissionStatusCountParams,
  EssayGetSubmissionStatusParams,
  ExhibitionGetSubmissionStatusCountParams,
  ExhibitionGetSubmissionStatusParams,
  IBCasPortfolioGetSubmissionStatusByInterviewCountParams,
  IBCasPortfolioGetSubmissionStatusByInterviewParams,
  IBCasPortfolioGetSubmissionStatusByReflectionDiaryParams,
  IBGetSubmissionStatusCountParams,
  IBGetSubmissionStatusParams,
  IBGetTokExhibitionPlanSubmissionStatusCountParams,
  IBGetTokExhibitionPlanSubmissionStatusParams,
  IBGetTokOutlineSubmissionStatusCountParams,
  IBGetTokOutlineSubmissionStatusParams,
  RPPFGetSubmissionStatusCountParams,
  RPPFGetSubmissionStatusParams,
  RRSGetSubmissionStatusCountParams,
  RRSGetSubmissionStatusParams,
  TKPPFGetSubmissionStatusCountParams,
  TKPPFGetSubmissionStatusParams,
} from 'src/generated/model';

export const useIBProposalStatus = ({ grade, klass }: IBGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useIBGetSubmissionStatusCount({ grade, klass });

  return {
    data,
    isLoading,
  };
};

export const useIBProposalSubmissionStatus = ({ grade, klass, status }: IBGetSubmissionStatusParams) => {
  const { data, isLoading } = useIBGetSubmissionStatus({ grade, klass, status });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};

export const useIBActivityLogStatus = ({ grade, klass }: IBGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useIBCasPortfolioGetSubmissionStatusByReflectionDiaryCount({ grade, klass });

  return {
    data,
    isLoading,
  };
};

export const useIBActivityLogSubmissionStatus = ({
  grade,
  klass,
  status,
}: IBCasPortfolioGetSubmissionStatusByReflectionDiaryParams) => {
  const { data, isLoading } = useIBCasPortfolioGetSubmissionStatusByReflectionDiary({ grade, klass, status });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};
export const useIBInterviewStatus = ({ grade, klass }: IBCasPortfolioGetSubmissionStatusByInterviewCountParams) => {
  const { data, isLoading } = useIBCasPortfolioGetSubmissionStatusByInterviewCount({ grade, klass });

  return {
    data,
    isLoading,
  };
};

export const useIBInterviewSubmissionStatus = ({
  grade,
  klass,
  status,
}: IBCasPortfolioGetSubmissionStatusByInterviewParams) => {
  const { data, isLoading } = useIBCasPortfolioGetSubmissionStatusByInterview({ grade, klass, status });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};

export const useIBEssayStatus = ({ grade, klass, ibType }: EssayGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useEssayGetSubmissionStatusCount({ grade, klass, ibType });

  return {
    data,
    isLoading,
  };
};

export const useIBEssaySubmissionStatus = ({ grade, klass, ibType, status }: EssayGetSubmissionStatusParams) => {
  const { data, isLoading } = useEssayGetSubmissionStatus({ grade, klass, status, ibType });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};

export const useIBRPPFStatus = ({ grade, klass }: RPPFGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useRPPFGetSubmissionStatusCount({ grade, klass });

  return {
    data,
    isLoading,
  };
};

export const useIBRPPFSubmissionStatus = ({ grade, klass, status }: RPPFGetSubmissionStatusParams) => {
  const { data, isLoading } = useRPPFGetSubmissionStatus({ grade, klass, status });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};

export const useIBRRSStatus = ({ grade, klass, ibType }: RRSGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useRRSGetSubmissionStatusCount({ grade, klass, ibType });

  return {
    data,
    isLoading,
  };
};

export const useIBRRSSubmissionStatus = ({ grade, klass, status, ibType }: RRSGetSubmissionStatusParams) => {
  const { data, isLoading } = useRRSGetSubmissionStatus({ grade, klass, status, ibType });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};

// TOK 전시회 기획안 현황관리 상태별 학생수 조회 (선생님 / 코디네이터)
export const useIBTOKStatusCount = ({ grade, klass }: IBGetTokExhibitionPlanSubmissionStatusCountParams) => {
  const { data, isLoading } = useIBGetTokExhibitionPlanSubmissionStatusCount({ grade, klass });

  return {
    data,
    isLoading,
  };
};

// TOK 전시회 기획안 현황관리 상태별 학생 상세 정보 조회 (선생님 / 코디네이터)
export const useIBTOKExhibitionPlanSubmissionStatus = ({
  grade,
  klass,
  status,
}: IBGetTokExhibitionPlanSubmissionStatusParams) => {
  const { data, isLoading } = useIBGetTokExhibitionPlanSubmissionStatus({ grade, klass, status });
  const students = data?.items;

  return {
    students,
    isLoading,
  };
};

// TOK 전시회 현황관리 상태별 학생수 조회 (선생님 / 코디네이터)
export const useIBTOKExhibitionStatusCount = ({ grade, klass }: ExhibitionGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useExhibitionGetSubmissionStatusCount({ grade, klass });

  return {
    data,
    isLoading,
  };
};

// TOK 전시회 현황관리 상태별 학생 상세 정보 조회 (선생님 / 코디네이터)
export const useIBTOKExhibitionSubmissionStatus = ({ grade, klass, status }: ExhibitionGetSubmissionStatusParams) => {
  const { data, isLoading } = useExhibitionGetSubmissionStatus({ grade, klass, status });
  const students = data?.items;

  return {
    students,
    isLoading,
  };
};

// TOK 아웃라인 현황관리 상태별 학생수 조회 (선생님 / 코디네이터)
export const useIBTOKOutlineStatusCount = ({ grade, klass }: IBGetTokOutlineSubmissionStatusCountParams) => {
  const { data, isLoading } = useIBGetTokOutlineSubmissionStatusCount({ grade, klass });

  return {
    data,
    isLoading,
  };
};

// TOK 아웃라인 현황관리 상태별 학생 상세 정보 조회 (선생님 / 코디네이터)
export const useIBTOKOutlineSubmissionStatus = ({ grade, klass, status }: IBGetTokOutlineSubmissionStatusParams) => {
  const { data, isLoading } = useIBGetTokOutlineSubmissionStatus({ grade, klass, status });
  const students = data?.items;

  return {
    students,
    isLoading,
  };
};

// TOK TKPPF 현황관리 상태별 학생수 조회 (선생님 / 코디네이터)
export const useIBTOKPPFStatusCount = ({ grade, klass }: TKPPFGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useTKPPFGetSubmissionStatusCount({ grade, klass });

  return {
    data,
    isLoading,
  };
};

// TOK TKPPF 현황관리 상태별 학생 상세 정보 조회 (선생님 / 코디네이터)
export const useIBTOKPPFSubmissionStatus = ({ grade, klass, status }: TKPPFGetSubmissionStatusParams) => {
  const { data, isLoading } = useTKPPFGetSubmissionStatus({ grade, klass, status });
  const students = data?.items;

  return {
    students,
    isLoading,
  };
};
