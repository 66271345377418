export const EE_SUBJECTS = [
  { id: 0, value: '과목 전체' },
  { id: 1, value: '언어와문학' },
  { id: 2, value: '수학 분석과 접근' },
  { id: 3, value: '세계사' },
  { id: 4, value: '생명과학' },
  { id: 5, value: '화학' },
  { id: 6, value: '영어B' },
  { id: 7, value: '영어연극이론과 창작(영어)' },
  { id: 8, value: '비주얼아트(영어)' },
  { id: 9, value: '물리(영어)' },
  { id: 10, value: '컴퓨터과학(영어)' },
];

export const EE_SUBJECTS_CREATE = [
  { id: 1, value: '언어와문학' },
  { id: 2, value: '수학 분석과 접근' },
  { id: 3, value: '세계사' },
  { id: 4, value: '생명과학' },
  { id: 5, value: '화학' },
  { id: 6, value: '영어B' },
  { id: 7, value: '영어연극이론과 창작(영어)' },
  { id: 8, value: '비주얼아트(영어)' },
  { id: 9, value: '물리(영어)' },
  { id: 10, value: '컴퓨터과학(영어)' },
];

export const CAS_LEARNINGOUTCOME = [
  { id: 1, value: '자신의 장점&성장 분야 개발' },
  { id: 2, value: '도전과제 수행&새로운 역량 개발' },
  { id: 3, value: 'CAS활동 추진&계획' },
  { id: 4, value: '노력과 인내심' },
  { id: 5, value: '개인의 역량 입증&협력의 이점 인식' },
  { id: 6, value: '세계적 이슈 참여' },
  { id: 7, value: '선택과 행동의 윤리적 측면' },
];
